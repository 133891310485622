import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import CampaignCard from "../components/campaign-card";
import Hero from "../components/hero";
import NewsletterBanner from "../components/newsletter-banner";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";

const PolicyTemplate = ({ data: { campaign, site } }) => {
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${site.siteMetadata.siteUrl}`,
					id: `${site.siteMetadata.siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Policies",
				item: {
					url: `${site.siteMetadata.siteUrl}/policies`,
					id: `${site.siteMetadata.siteUrl}/policies`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${campaign.title}`,
				item: {
					url: `${site.siteMetadata.siteUrl}/policies/${campaign.slug}`,
					id: `${site.siteMetadata.siteUrl}/policies/${campaign.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${campaign.title} | The Ella Roberta Foundation`}
				description="Policy Page"
				language="en"
			/>
			<section className="bg-primary py-5 py-lg-7">
				<Container>
					<Row>
						<Col>
							<div>
								{" "}
								<h1 className="text-white display-5 text-center mb-2  gobold-reg ">
									{campaign.title}
								</h1>
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<section>
				<Container>
					<Row className="justify-content-center">
						<Col className="position-relative py-5" lg={10} xl={8}>
							{parse(campaign.content)}
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default PolicyTemplate;

export const pageQuery = graphql`
	query PolicyPostById($id: String!) {
		campaign: wpPolicy(id: { eq: $id }) {
			title
			content
			slug
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
