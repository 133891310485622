import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const CampaignCard = ({
	title,
	image,
	uri,
	description,
	imageAlt,
	external,
}) => {
	return (
		<div
			className="w-100 h-100  position-relative"
			style={{
				boxShadow: "0px 3px 85px #00000029",
				borderRadius: "5px",
				overflow: "hidden",
			}}
		>
			<GatsbyImage
				style={{ height: "309px" }}
				class="w-100"
				image={image}
				alt={imageAlt}
			/>
			<div className="p-4">
				<h3 className="text-center mb-4">{title}</h3>
				<p className="text-center text-lg-start">{description}</p>
				<div style={{ height: "48px" }} className="text-center mt-4">
					{!external && (
						<Button
							style={{ bottom: "2rem" }}
							className="fs-5 px-4 position-absolute w-90 w-md-auto start-50 translate-middle-x  py-2"
							variant="outline-black"
							to={uri}
							as={Link}
						>
							READ MORE
						</Button>
					)}
					{external && (
						<Button
							style={{ bottom: "2rem" }}
							className="fs-5 px-4 position-absolute w-90 w-md-auto start-50 translate-middle-x  py-2"
							variant="outline-black"
							target="_blank"
							rel="noreferrer"
							href={uri}
						>
							READ MORE
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default CampaignCard;
